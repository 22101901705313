import { useTranslate } from '/machinery/I18n'

import { HeadingXxs, HeadingXl } from '/features/buildingBlocks/Heading'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'

import styles from './FAQ.css'

export function FAQ({ faq }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <HeadingXl h='3' title={__`faq`} />

      <div className={styles.accordionContainer}>
        {Boolean(faq?.length) && <Accordion items={faq} />}
      </div>
    </div>
  )
}

function Accordion({ items }) {
  const [activeCard, setActiveCard] = React.useState({ id: undefined, active: false })

  return (
    <ul className={styles.componentAccordion}>
      {items.map(({ _key, question, answer }, i) => (
        <Item
          key={`${i}-${_key}`}
          id={i}
          title={question}
          block={answer}
          isActive={i === activeCard.id && activeCard.active}
          onOpenChange={handleOpenChange}
        />
      ))}
    </ul>
  )

  function handleOpenChange(id) {
    setActiveCard(prevCard => ({ id, active: prevCard.id === id ? !activeCard.active : true }))
  }
}

function Item({ id, title, block, isActive, onOpenChange }) {
  return (
    <li className={styles.componentItem}>
      <button
        data-x='click-on-faq-card'
        onClick={() => onOpenChange(id)}
        className={cx(styles.button, isActive && styles.isActive)}
      >
        {title && <HeadingXxs h='4' {...{ title }} />}
        <ButtonIcon layoutClassName={styles.buttonIconLayout} {...{ isActive }} />
      </button>

      <div className={cx(styles.answerContainer, isActive && styles.isActive)}>
        <div className={styles.portableTextContainer}>
          <PortableTextMainContent value={block} />
        </div>
      </div>
    </li>
  )
}

function ButtonIcon({ isActive, layoutClassName = undefined }) {
  return <span className={cx(styles.componentButtonIcon, isActive && styles.isActive, layoutClassName)} />
}
